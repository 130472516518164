<template>
  <v-container>
    <v-card class="pa-6">
      <v-card-title
        class="text-center text-h6 text-sm-h3 text-sm-h4 blue--text "
        >Obrigado por negociar com a<br />
        Easycob!</v-card-title
      >
      <v-card-text class="text-subtitle-1">
        Seu boleto será encaminhado Por e-mail em até 24 horas, mas não se
        preocupe pode chegar antes! <br /><br />
        Após o recebimento do seu boleto talvez ele ainda não esteja disponivel
        para pagamento no Banco, o processo de Registro pode demorar até 72
        horas. <br /><br />
        Seu boleto pode ser pago pelos canais digitais,internet banking ou
        qualquer banco e casa lotérica. <br /><br />
        Desta forma se ao tentar pagar você receber alguma mensagem de boleto
        indisponível, por favor tente novamente mais tarde. <br /><br />
        Em caso de Dúvidas estamos à disposição para ajudá-lo <br /><br />
        Seguem nossos contato(s) :
      </v-card-text>

      <v-card class="pa-3 blue--text" width="500">
        <h1>Fone</h1>
        <h3 class="indigo--text pb-8">(21) 2206-4883</h3>
        <v-divider></v-divider>
        <h1>Whatsapp</h1>
        <h3 class="indigo--text pb-8">(21) 95101-6864</h3>
        <v-divider></v-divider>
        <h1>Email</h1>
        <h3 class="indigo--text pb-8">boleto@easboleto.com.br</h3>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Dscard2",
};
</script>

<style></style>
